<template>
  <div class="container">
    <div class="wishlist">
      <h1
        v-if="initialized && hasProducts"
        class="text text--xs-header-3 text--xs-center text--md-left text--sm-header-2 text--sm-strong text--sm-mt-1 text--sm-mb-1"
      >
        Polecane zdjęcia
      </h1>

      <div
        :class="{
          'wishlist__content--loading': loading,
        }"
        class="wishlist__content"
      >
        <template v-if="initialized && hasProducts">
          <p class="wishlist__info">
            Możesz dodać polecane zdjęcia do koszyka lub do
            <router-link
              :to="wishlistRoute"
              class="wishlist__info-link"
              target="_blank"
              >ulubionych
            </router-link>
          </p>

          <Images-grid :images="products" :has-remove-button="true" />
        </template>

        <ImagesGridSkeleton
          v-if="!initialized && loading"
          :fixed-layout="true"
          :rows-count="2"
        />

        <div
          v-if="initialized && !loading && !hasProducts"
          class="wishlist__empty"
        >
          <p>
            Brak polecanych zdjęć
          </p>
        </div>

        <p class="wishlist__button">
          <router-link
            :to="wishlistRoute"
            target="_blank"
            class="button button--small button--strong button--go-to-wishlist"
          >
            Przejdź do ulubionych
            <template v-if="count > 0"> ({{ count }})</template>
          </router-link>
        </p>
      </div>
    </div>
    <!-- /.wishlist -->
  </div>
  <!-- /.container -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImagesGrid from '../images-grid/images-grid.vue';
import ImagesGridSkeleton from '../images-grid/images-grid-skeleton.vue';
import eventBus from '../../utils/eventBus';

export default {
  name: 'WishlistRecommended',
  components: {
    ImagesGrid,
    ImagesGridSkeleton,
  },
  props: {
    listId: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    localInitialized: false,
    wishlistId: '',
  }),
  metaInfo: {
    title: 'Polecane zdjęcia',
  },
  computed: {
    ...mapState('wishlist', {
      products: 'recommendedProducts',
      ids: 'ids',
      count: 'count',
      loading: 'loading',
      productsInitialized: 'recommendedProductsInitialized',
    }),
    hasProducts() {
      return this.products.length > 0;
    },
    initialized() {
      return this.productsInitialized || this.localInitialized;
    },
    wishlistRoute() {
      return {
        name: 'wishlist',
        params: {
          listId: this.wishlistId,
        },
      };
    },
  },
  created() {
    this.setListId();
    this.fetch();
    eventBus.$on('wishlist-id-updated', this.setListId);
  },
  methods: {
    ...mapActions('wishlist', [
      'fetchProductsByIds',
      'fetchRecommendedProductsByRefId',
    ]),
    async fetch() {
      try {
        await this.fetchRecommendedProductsByRefId(this.listId);
        await this.fetchProductsByIds(this.ids);
      } catch (e) {
        this.$bugsnag.notify(e);
      } finally {
        this.localInitialized = true;
      }
    },
    setListId() {
      this.wishlistId = localStorage.getItem('wishlistId');
    },
  },
};
</script>
